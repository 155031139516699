import { useRouter } from "next/router";
import { useState, useEffect } from "react";

import { getSessionStorage, setSessionStorage } from "../utils/sessionStorage";

type RouteHistory = {
  brandSlug?: string;
  goodsId?: string;
};

export const useRouterHistory = () => {
  const router = useRouter();
  const [routeHistory, setRouteHistory] = useState<RouteHistory>({});

  useEffect(() => {
    const { brandSlug, goodsId } = router.query as {
      brandSlug?: string;
      goodsId?: string;
    };

    const rh = {
      ...getSessionStorage<RouteHistory>("history"),
      ...(brandSlug !== undefined && { brandSlug }),
      ...(goodsId !== undefined && { goodsId }),
    };

    setSessionStorage<RouteHistory>("history", rh);

    setRouteHistory(rh);
  }, [router.query]);

  return { ...routeHistory };
};
