import { onAuthStateChanged } from "firebase/auth";
import { useState, useEffect, createContext, useContext } from "react";

import { useFirebase } from "./firebase";

export type Auth =
  | {
      type: "loading";
    }
  | {
      type: "unauthorized";
    }
  | {
      type: "authorized";
      user: User;
    };

/**
 * FIXME https://github.com/balus-co-ltd/spwn-portal/pull/118#discussion_r1065322464
 */
// type Credential = {
//   uid: string;
//   image: string | undefined;
//   token: string;
// };

export type User = Readonly<{
  displayName: string;
  email: string;
  uid: string;
  icon: string;
}>;

const useAuth = (): Auth => {
  const [auth, setAuth] = useState<Auth>({ type: "loading" });
  const { firebase } = useFirebase();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebase.auth, async (user) => {
      if (user === null) {
        setAuth({
          type: "unauthorized",
        });
      } else {
        setAuth({
          type: "authorized",
          user: {
            displayName: user.displayName ?? "",
            email: user.email ?? "",
            uid: user.uid,
            icon: user.photoURL ?? "",
          },
        });
      }
    });
    return () => {
      unsubscribe();
    };
  }, [firebase.auth]);

  return auth;
};

export const AuthContext = createContext<ReturnType<typeof useAuth>>({
  type: "loading",
});

export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const value = useAuth();
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
