import { getApps, initializeApp } from "firebase/app";
import { Auth, getAuth, connectAuthEmulator } from "firebase/auth";
import { Firestore, getFirestore } from "firebase/firestore";
import {
  getFunctions,
  Functions,
  connectFunctionsEmulator,
} from "firebase/functions";
import { FirebaseStorage, getStorage } from "firebase/storage";
import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
} from "react";

import { useConfig } from "./config";

export type Firebase = Readonly<{
  auth: Auth;
  firestore: Firestore;
  functions: Functions;
  storage: FirebaseStorage;
}>;

type FirebaseContext = { firebase: Firebase };
const FirebaseContext = createContext<FirebaseContext>({} as FirebaseContext);

const useFirebaseContext = (): FirebaseContext => {
  const context = useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error(`Must be used within a FirebaseContextProvider`);
  }
  return context;
};

export const FirebaseContextProvider: FC<{
  value?: { firebase: Firebase };
  children?: React.ReactNode;
}> = ({ value, children }) => {
  const {
    config: { firebase: firebaseConfig, emulators },
  } = useConfig();

  const portal = useMemo(
    () =>
      getApps().find((app) => app.name === "portal") ??
      initializeApp(firebaseConfig, "portal"),
    [firebaseConfig]
  );

  const auth = useMemo(() => getAuth(portal), [portal]);
  const firestore = useMemo(() => getFirestore(portal), [portal]);
  const functions = useMemo(() => getFunctions(portal), [portal]);
  const storage = useMemo(() => getStorage(portal), [portal]);

  useEffect(() => {
    if (emulators !== undefined) {
      try {
        /**
         * @see https://firebase.google.com/docs/emulator-suite/connect_functions?hl=ja
         */
        connectFunctionsEmulator(
          functions,
          emulators.functions.host,
          emulators.functions.port
        );
        connectAuthEmulator(
          auth,
          `http://${emulators.auth.host}:${emulators.auth.port}`
        );
      } catch (_) {
        // DO NOTHING
      }
    }
  }, [functions, auth, emulators]);

  return (
    <FirebaseContext.Provider
      value={
        value ?? {
          firebase: {
            auth,
            firestore,
            functions,
            storage,
          },
        }
      }
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = () => {
  return useFirebaseContext();
};

export const MockFirebaseContext: FC<{
  value?: Partial<FirebaseContext>;
  children: React.ReactNode;
}> = ({ value, children }): JSX.Element => {
  return (
    <FirebaseContext.Provider
      value={{
        firebase: {
          ...value?.firebase,
          auth: {} as Auth,
          firestore: {} as Firestore,
          functions: {} as Functions,
          storage: {} as FirebaseStorage,
        },
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
