var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"72a021931ed9a3a812c75010005c24c17fc58f53"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const ENV = process.env.NEXT_PUBLIC_VERCEL_ENV || "local"; // production, preview, or development

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://d54a879f68af41178560833117a4ac9b@o503713.ingest.sentry.io/4504751389081600",
  enabled: ENV !== "local",
  tracesSampleRate: process.env.NODE_ENV === "production" ? 0.1 : 1.0, // quota上限に達しないように本番環境では低めの値を設定する

  integrations: [
    // https://docs.sentry.io/platforms/javascript/configuration/integrations/captureconsole/
    new CaptureConsole({
      levels: ["error"],
    }),
  ],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
