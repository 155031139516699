/**
 * Provide swr client.
 */

import { FC } from "react";
import useSWRClient, {
  SWRConfig,
  Key,
  Fetcher,
  SWRConfiguration,
  BareFetcher,
  KeyedMutator,
} from "swr";
import useSWRImmutableClient from "swr/immutable";
import useSWRMutationClient from "swr/mutation";
export type { SWRResponse } from "swr";
export const useSWR = useSWRClient;
/**
 * Enable suspense by default.
 * @see https://swr.vercel.app/docs/suspense
 */
const defaultOptions = {
  suspense: true,
};

export const SWRContextProvider: FC<{
  value?: Partial<Parameters<typeof SWRConfig>[0]>["value"];
  children?: React.ReactNode;
}> = ({ children, value }) => {
  return (
    <SWRConfig value={{ ...defaultOptions, ...value }}>{children}</SWRConfig>
  );
};

/**
 * SWR client
 * デフォルトでSuspenseが有効にしている
 * @see https://swr.vercel.app/docs/suspense
 *
 * FIXME 取得データからundefinedをなくしている。もっとスマートな方法求む。2系になったら直るかも。
 * Suspenseオプションを使用すれば、取得データのundefinedを考慮しなくて済むはずだが、現状型レベルでは解決してくれないため。
 * @todo 条件付きfetchでは依然として取得データはundefinedになるので修正
 */
export const useSWRSuspense = useSWRClient as SWRHook;
export const useSWRImmutable = useSWRImmutableClient as SWRHook;
export const useSWRMutation = useSWRMutationClient;
/**
 * Copy from SWRHook in swr lib.
 */
interface SWRHook {
  <Data = unknown, Error = unknown, SWRKey extends Key = null>(
    key: SWRKey
  ): SWRSuspenseResponse<Data, Error>;

  <Data = unknown, Error = unknown, SWRKey extends Key = null>(
    key: SWRKey,
    fetcher: Fetcher<Data, SWRKey> | null
  ): SWRSuspenseResponse<Data, Error>;

  <Data = unknown, Error = unknown, SWRKey extends Key = null>(
    key: SWRKey,
    config: SWRConfiguration<Data, Error, Fetcher<Data, SWRKey>> | undefined
  ): SWRSuspenseResponse<Data, Error>;

  <Data = unknown, Error = unknown, SWRKey extends Key = null>(
    key: SWRKey,
    fetcher: Fetcher<Data, SWRKey> | null,
    config: SWRConfiguration<Data, Error, Fetcher<Data, SWRKey>> | undefined
  ): SWRSuspenseResponse<Data, Error>;

  <Data = unknown, Error = unknown>(key: Key): SWRSuspenseResponse<Data, Error>;

  <Data = unknown, Error = unknown>(
    key: Key,
    fetcher: BareFetcher<Data> | null
  ): SWRSuspenseResponse<Data, Error>;

  <Data = unknown, Error = unknown>(
    key: Key,
    config: SWRConfiguration<Data, Error, BareFetcher<Data>> | undefined
  ): SWRSuspenseResponse<Data, Error>;

  <Data = unknown, Error = unknown>(
    key: Key,
    fetcher: BareFetcher<Data> | null,
    config: SWRConfiguration<Data, Error, BareFetcher<Data>> | undefined
  ): SWRSuspenseResponse<Data, Error>;
}

/**
 * Copy from SWRResponse in swr lib.
 */
export interface SWRSuspenseResponse<Data = unknown, Error = unknown> {
  data: Data;
  error?: Error;
  mutate: KeyedMutator<Data>;
  isValidating: boolean;
}
