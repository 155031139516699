export const setSessionStorage = <T>(key: string, value: T): void => {
  if (typeof window !== "undefined") {
    try {
      const serializedValue = JSON.stringify(value);
      sessionStorage.setItem(key, serializedValue);
    } catch (error) {
      console.error(`Error setting sessionStorage item: ${error}`);
    }
  }
};

export const getSessionStorage = <T>(key: string): StorageValue<T> => {
  if (typeof window !== "undefined") {
    try {
      const serializedValue = sessionStorage.getItem(key);
      return serializedValue ? (JSON.parse(serializedValue) as T) : null;
    } catch (error) {
      console.error(`Error getting sessionStorage item: ${error}`);
      return null;
    }
  }
  return null;
};

type StorageValue<T> = T | null;
