import {
  extendTheme,
  ChakraTheme,
  theme as defaultChakraTheme,
  SystemStyleFunction,
} from "@spwn-portal/ui/chakra";
import { defineStyle } from "@spwn-portal/ui/chakra-styled-system";

// TODO: Figmaで定義されているxxsを定義して指定したり、breakpointsをFigmaのものに合わせて定義したい

const colors = {
  balusBlack: {
    50: "#F5F5F5",
    100: "#F1F1F1",
    200: "#EBEBEB",
    300: "#D6D6D6",
    400: "#C2C2C2",
    500: "#A3A3A3",
    600: "#858585",
    700: "#5C5C5C",
    800: "#333333",
    900: "#141414",
  },
  balusGreen: {
    50: "#d9fffd",
    100: "#adfff8",
    200: "#7dfff1",
    300: "#4dffec",
    400: "#26ffe6",
    500: "#15e6cd",
    600: "#00C2AD",
    700: "#008072",
    800: "#004e44",
    900: "#001c18",
  },
} as const;
// } satisfies ChakraTheme["colors"]; FIXME: next13にするか、 https://github.com/vercel/next.js/discussions/40895 が解決するのを待つ

const fontFamily = `"游ゴシック体", YuGothic, "Yu Gothic M", "游ゴシック Medium", "Yu Gothic Medium", "ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック", sans-seif`;

const fonts: ChakraTheme["fonts"] = {
  heading: fontFamily,
  body: fontFamily,
};

const config: ChakraTheme["config"] = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const semanticTokens = {
  colors: {
    surface: {
      default: defaultChakraTheme.colors.white,
    },
    "surface.hovered": {
      default: defaultChakraTheme.colors.gray[50],
    },
    "surface.selected": {
      default: defaultChakraTheme.colors.gray[50],
    },
    "surface.disabled": {
      default: defaultChakraTheme.colors.gray[100],
    },
    "surface.pressed": {
      default: defaultChakraTheme.colors.gray[100],
    },
    "surface.depressed": {
      default: defaultChakraTheme.colors.gray[100],
    },
    "surface.subdued": {
      default: defaultChakraTheme.colors.gray[50],
    },
    "surface.subdued.hovered": {
      default: defaultChakraTheme.colors.gray[100],
    },
    "surface.subdued.selected": {
      default: defaultChakraTheme.colors.gray[100],
    },
    "surface.subdued.pressed": {
      default: defaultChakraTheme.colors.gray[200],
    },
    "surface.dark": {
      default: colors.balusBlack[900],
    },
    "surface.dark.hovered": {
      default: colors.balusBlack[600],
    },
    "surface.dark.disabled": {
      default: colors.balusBlack[400],
    },
    "surface.dark.selected": {
      default: colors.balusBlack[700],
    },
    "surface.secondary": {
      default: defaultChakraTheme.colors.gray[100],
    },
    "surface.secondary.hovered": {
      default: defaultChakraTheme.colors.gray[200],
    },
    "surface.secondary.selected": {
      default: defaultChakraTheme.colors.gray[200],
    },
    "surface.secondary.disabled": {
      default: defaultChakraTheme.colors.gray[50],
    },
    "surface.secondary.pressed": {
      default: defaultChakraTheme.colors.gray[300],
    },
    "surface.critical": {
      default: defaultChakraTheme.colors.red[500],
    },
    "surface.critical.subdued": {
      default: defaultChakraTheme.colors.red[100],
    },
    "surface.warning": {
      default: defaultChakraTheme.colors.yellow[500],
    },
    "surface.warning.subdued": {
      default: defaultChakraTheme.colors.yellow[50],
    },
    "surface.success": {
      default: defaultChakraTheme.colors.green[500],
    },
    "surface.success.subdued": {
      default: defaultChakraTheme.colors.yellow[50],
    },
    "surface.highlight": {
      default: defaultChakraTheme.colors.cyan[500],
    },
    "surface.highlight.subdued": {
      default: defaultChakraTheme.colors.cyan[50],
    },

    "surface.ondark.hovered": {
      default: defaultChakraTheme.colors.whiteAlpha[100],
    },
    "surface.ondark.pressed": {
      default: defaultChakraTheme.colors.gray[800],
    },
    "surface.ondark.disabled": {
      default: defaultChakraTheme.colors.whiteAlpha[300],
    },
    "surface.onsubdued.hovered": {
      default: defaultChakraTheme.colors.blackAlpha[200],
    },
    "surface.onsubdued.pressed": {
      default: defaultChakraTheme.colors.blackAlpha[300],
    },
    "surface.onsubdued.disabled": {
      default: defaultChakraTheme.colors.blackAlpha[50],
    },
    "surface.primary": {
      default: colors.balusGreen[600],
    },
    "surface.primary.subdued": {
      default: colors.balusGreen[50],
    },

    text: {
      default: defaultChakraTheme.colors.blackAlpha[900],
    },
    "text.disabled": {
      default: defaultChakraTheme.colors.blackAlpha[400],
    },
    "text.subdued": {
      default: defaultChakraTheme.colors.blackAlpha[600],
    },
    "text.subdued.disabled": {
      default: defaultChakraTheme.colors.blackAlpha[400],
    },
    "text.ondark": {
      default: defaultChakraTheme.colors.white,
    },
    "text.ondark.subdued": {
      default: defaultChakraTheme.colors.whiteAlpha[800],
    },
    "text.primary": {
      default: colors.balusGreen[600],
    },
    "text.primary.hovered": {
      default: colors.balusGreen[700],
    },
    "text.primary.disabled": {
      default: defaultChakraTheme.colors.gray[400],
    },
    "text.primary.pressed": {
      default: colors.balusGreen[800],
    },
    "text.secondary": {
      default: defaultChakraTheme.colors.gray[600],
    },
    "text.secondary.hovered": {
      default: defaultChakraTheme.colors.gray[700],
    },
    "text.secondary.selected": {
      default: defaultChakraTheme.colors.gray[700],
    },
    "text.secondary.disabled": {
      default: defaultChakraTheme.colors.gray[400],
    },
    "text.secondary.pressed": {
      default: defaultChakraTheme.colors.gray[800],
    },
    "text.critical": {
      default: defaultChakraTheme.colors.red[700],
    },
    "text.warning": {
      default: defaultChakraTheme.colors.yellow[700],
    },
    "text.success": {
      default: defaultChakraTheme.colors.green[700],
    },
    "text.highlight": {
      default: defaultChakraTheme.colors.cyan[700],
    },

    "action.primary": {
      default: colors.balusGreen[600],
    },
    "action.primary.hovered": {
      default: colors.balusGreen[700],
    },
    "action.primary.disabled": {
      default: defaultChakraTheme.colors.gray[400],
    },
    "action.primary.pressed": {
      default: colors.balusGreen[800],
    },

    "action.secondary": {
      default: defaultChakraTheme.colors.gray[500],
    },
    "action.secondary.hovered": {
      default: defaultChakraTheme.colors.gray[600],
    },
    "action.secondary.disabled": {
      default: defaultChakraTheme.colors.gray[400],
    },
    "action.secondary.pressed": {
      default: defaultChakraTheme.colors.gray[700],
    },
    "action.critical": {
      default: defaultChakraTheme.colors.red[500],
    },
    "action.critical.hovered": {
      default: defaultChakraTheme.colors.red[600],
    },
    "action.critical.disabled": {
      default: defaultChakraTheme.colors.gray[400],
    },
    "action.critical.pressed": {
      default: defaultChakraTheme.colors.red[700],
    },

    "action.warning": {
      default: defaultChakraTheme.colors.yellow[500],
    },
    "action.warning.hovered": {
      default: defaultChakraTheme.colors.yellow[600],
    },
    "action.warning.disabled": {
      default: defaultChakraTheme.colors.gray[400],
    },
    "action.warning.pressed": {
      default: defaultChakraTheme.colors.yellow[700],
    },

    border: {
      default: defaultChakraTheme.colors.blackAlpha[300],
    },
    "border.hovered": {
      default: defaultChakraTheme.colors.blackAlpha[500],
    },
    "border.disabled": {
      default: defaultChakraTheme.colors.blackAlpha[100],
    },
    "border.primary": {
      default: colors.balusGreen[600],
    },
    "border.secondary": {
      default: defaultChakraTheme.colors.gray[500],
    },
    "border.critical": {
      default: defaultChakraTheme.colors.red[500],
    },
    "border.warning": {
      default: defaultChakraTheme.colors.yellow[500],
    },
    "border.success": {
      default: defaultChakraTheme.colors.green[500],
    },
    "border.highlight": {
      default: defaultChakraTheme.colors.cyan[500],
    },
    "border.ondark": {
      default: defaultChakraTheme.colors.whiteAlpha[700],
    },

    icon: {
      default: defaultChakraTheme.colors.blackAlpha[900],
    },
    "icon.disabled": {
      default: defaultChakraTheme.colors.blackAlpha[400],
    },
    "icon.subdued": {
      default: defaultChakraTheme.colors.blackAlpha[500],
    },
    "icon.subdued.disabled": {
      default: defaultChakraTheme.colors.blackAlpha[200],
    },
    "icon.ondark": {
      default: defaultChakraTheme.colors.white,
    },
    "icon.ondark.subdued": {
      default: defaultChakraTheme.colors.whiteAlpha[800],
    },
    "icon.ondark.disabled": {
      default: defaultChakraTheme.colors.whiteAlpha[700],
    },
    "icon.primary": {
      default: colors.balusGreen[600],
    },
    "icon.primary.hovered": {
      default: colors.balusGreen[700],
    },
    "icon.primary.disabled": {
      default: defaultChakraTheme.colors.gray[400],
    },
    "icon.primary.pressed": {
      default: colors.balusGreen[800],
    },
    "icon.secondary": {
      default: defaultChakraTheme.colors.gray[500],
    },
    "icon.secondary.hovered": {
      default: defaultChakraTheme.colors.gray[600],
    },
    "icon.secondary.selected": {
      default: defaultChakraTheme.colors.gray[600],
    },
    "icon.secondary.disabled": {
      default: defaultChakraTheme.colors.gray[400],
    },
    "icon.secondary.pressed": {
      default: defaultChakraTheme.colors.gray[700],
    },
    "icon.critical": {
      default: defaultChakraTheme.colors.red[500],
    },
    "icon.warning": {
      default: defaultChakraTheme.colors.yellow[500],
    },
    "icon.success": {
      default: defaultChakraTheme.colors.green[500],
    },
    "icon.highlight": {
      default: defaultChakraTheme.colors.cyan[500],
    },

    "tag.primary": {
      default: "#3182CE",
    },
    "tag.secondary": {
      default: "#D5408C",
    },
    "tag.tertiary": {
      default: "#DD6B20",
    },
    "tag.quaternary": {
      default: "#00B5D8",
    },
    "tag.quinary": {
      default: "#805ad5",
    },
    "tag.senary": {
      default: "#E53E3E",
    },
    "tag.octonary": {
      default: "rgba(0, 0, 0, 0.6)",
    },

    /** @todo 以下必要であれば随時書き換える */
    interactive: {
      default: defaultChakraTheme.colors.blue[500],
    },
    //   "hovered": {
    //     "value": "{blue.600}"
    //   },
    //   "selected": {
    //     "(default)": {
    //       "value": "{blue.500}"
    //     },
    //     "subdued": {
    //       "value": "{blue.100}"
    //     }
    //   },
    //   "disabled": {
    //     "value": "{gray.200}"
    //   },
    //   "pressed": {
    //     "value": "{blue.700}"
    //   },
    //   "focused": {
    //     "value": "{blue.500}"
    //   },
    //   "secondary": {
    //     "(default)": {
    //       "value": "{gray.500}"
    //     },
    //     "hovered": {
    //       "value": "{gray.600}"
    //     },
    //     "selected": {
    //       "value": "{gray.500}"
    //     },
    //     "disabled": {
    //       "value": "{gray.200}"
    //     },
    //     "pressed": {
    //       "value": "{gray.700}"
    //     }
    //   },
    //   "critical": {
    //     "(default)": {
    //       "value": "{red.500}"
    //     },
    //     "hovered": {
    //       "value": "{red.600}"
    //     },
    //     "selected": {
    //       "value": "{red.500}"
    //     },
    //     "disabled": {
    //       "value": "{red.300}"
    //     },
    //     "pressed": {
    //       "value": "{red.700}"
    //     }
    //   }
    // }
  },
};

/**
 * Text
 * @see https://v2.chakra-ui.com/docs/styled-system/text-and-layer-styles#text-styles
 */
const textStylesBase = {
  "2xs": {
    fontSize: "2xs",
    lineHeight: 3,
  },
  xs: {
    fontSize: "xs",
    lineHeight: 4,
  },
  sm: {
    fontSize: "sm",
    lineHeight: 5,
  },
  md: {
    fontSize: "md",
    lineHeight: 6,
  },
  lg: {
    fontSize: "lg",
    lineHeight: 7,
  },
  xl: {
    fontSize: "xl",
    lineHeight: 7,
  },
  "2xl": {
    fontSize: "2xl",
    lineHeight: 8,
  },
  "3xl": {
    fontSize: "3xl",
    lineHeight: 9,
  },
  "4xl": {
    fontSize: "4xl",
    lineHeight: 10,
  },
  "5xl": {
    fontSize: "5xl",
  },
  "6xl": {
    fontSize: "6xl",
  },
} as const;

const addWeightToTextStyle = (
  size: keyof typeof textStylesBase,
  fontWeight: "normal" | "bold"
) => {
  return {
    ...textStylesBase[size],
    fontWeight,
  };
};

const textStyles = {
  "2xs": addWeightToTextStyle("2xs", "normal"),
  "2xs-bold": addWeightToTextStyle("2xs", "bold"),
  // Figmaではxxsという名前で定義されているので定義している. 2xsと指定した場合都同じ
  xxs: addWeightToTextStyle("2xs", "normal"),
  "xxs-bold": addWeightToTextStyle("2xs", "bold"),
  xs: addWeightToTextStyle("xs", "normal"),
  "xs-bold": addWeightToTextStyle("xs", "bold"),
  sm: addWeightToTextStyle("sm", "normal"),
  "sm-bold": addWeightToTextStyle("sm", "bold"),
  md: addWeightToTextStyle("md", "normal"),
  "md-bold": addWeightToTextStyle("md", "bold"),
  lg: addWeightToTextStyle("lg", "normal"),
  "lg-bold": addWeightToTextStyle("lg", "bold"),
  xl: addWeightToTextStyle("xl", "normal"),
  "xl-bold": addWeightToTextStyle("xl", "bold"),
  "2xl": addWeightToTextStyle("2xl", "normal"),
  "2xl-bold": addWeightToTextStyle("2xl", "bold"),
  "3xl": addWeightToTextStyle("3xl", "normal"),
  "3xl-bold": addWeightToTextStyle("3xl", "bold"),
  "4xl": addWeightToTextStyle("4xl", "normal"),
  "4xl-bold": addWeightToTextStyle("4xl", "bold"),
  "5xl": addWeightToTextStyle("5xl", "normal"),
  "5xl-bold": addWeightToTextStyle("5xl", "bold"),
  "6xl": addWeightToTextStyle("6xl", "normal"),
  "6xl-bold": addWeightToTextStyle("6xl", "bold"),
  // NOTE: Figmaではcomponent-heading-large, component-heading-small, component-textも定義されているが、基本使われていないため定義していない
  // もし必要になったらここに定義しても良いが、その前にデザイナーに他の既存のトークンを指定することができないか相談しても良いかも
  // "heading-sm": {},
  // "heading-md": {},
  // "heading-lg": {},
  // "heading-2xl": {},
  // "heading-3xl": {},
  // "heading-4xl": {},
  // "heading-5xl": {},
} as const;

/**
 * Button
 * @see https://www.figma.com/file/4xi86afryPffe1BC2s5mEc/CMS-%E7%94%BB%E9%9D%A2%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3(Alias)?node-id=341%3A16890
 */
const primaryButton: SystemStyleFunction = () => {
  return {
    bg: "action.primary",
    color: "text.ondark",
    border: "1px solid",
    borderColor: "action.primary",
    _hover: {
      bg: "action.primary.hovered",
      color: "text.ondark",
      borderColor: "action.primary.hovered",
      _disabled: {
        bg: "#94c9c5",
        color: "text.ondark",
      },
    },
    _disabled: {
      bg: "#94c9c5",
      color: "text.ondark",
      borderColor: "#94c9c5",
      _hover: {
        borderColor: "#94c9c5",
      },
    },
    _active: {
      bg: "action.primary.pressed",
      color: "text.ondark",
      borderColor: "action.primary.pressed",
    },
  };
};

const variantCritical: SystemStyleFunction = () => {
  return {
    bg: "action.critical",
    color: "text.ondark",
    _hover: {
      bg: "action.critical.hovered",
      color: "text.ondark",
      _disabled: {
        bg: "action.critical.disabled",
      },
    },
    _disabled: {
      bg: "action.critical.disabled",
      color: "text.ondark",
    },
    _active: {
      bg: "action.critical.pressed",
      color: "text.ondark",
    },
  };
};

const variantCriticalOutline: SystemStyleFunction = () => {
  return {
    bg: "white",
    color: "action.critical",
    border: "1px solid",
    borderColor: "action.critical",
    _hover: {
      bg: "action.critical.hovered",
      color: "text.ondark",
      borderColor: "action.critical.hovered",
      _disabled: {
        bg: "action.critical.disabled",
      },
    },
    _disabled: {
      bg: "action.critical.disabled",
      color: "text.ondark",
      borderColor: "action.critical.disabled",
    },
    _active: {
      bg: "action.critical.pressed",
      color: "text.ondark",
      borderColor: "action.critical.pressed",
    },
  };
};

const darkButton: SystemStyleFunction = () => {
  return {
    bg: "surface.dark",
    color: "text.ondark",
    border: "1px solid",
    borderColor: "surface.dark",
    _hover: {
      bg: "surface.dark.hovered",
      color: "text.ondark",
      borderColor: "surface.dark.hovered",
      _disabled: {
        bg: "#surface.dark.disabled",
        color: "text.ondark",
      },
    },
    _disabled: {
      bg: "surface.dark.disabled",
      color: "text.ondark",
      borderColor: "surface.dark.disabled",
      _hover: {
        borderColor: "surface.dark.disabled",
      },
    },
    _active: {
      bg: "surface.dark.selected",
      color: "text.ondark",
      borderColor: "surface.dark.selected",
    },
  };
};

const actionedButton: SystemStyleFunction = () => {
  return {
    bg: "white",
    color: "text.primary",
    border: "1px solid",
    borderColor: "action.primary",
    _hover: {
      bg: "white",
      color: "text.primary",
      _disabled: {
        bg: "white",
        color: "text.primary",
      },
    },
    _disabled: {
      bg: "white",
      color: "text.primary",
    },
    _active: {
      bg: "white",
      color: "text.primary",
    },
  };
};

const lineButton = defineStyle(() => ({
  bg: "transparent",
  color: "text",
  border: "1px solid",
  borderColor: "border",
  fontWeight: "bold",
  _hover: {
    bg: "surface.hovered",
    _disabled: {
      bg: "transparent",
      opacity: "0.4",
    },
  },
  _disabled: {
    opacity: "0.4",
  },
  _active: {
    opacity: "0.8",
  },
}));

const variantOutline = defineStyle(() => ({
  bg: "transparent",
  color: "action.primary",
  border: "1px solid",
  borderColor: "action.primary",
  fontWeight: "bold",
  _hover: {
    bg: "blue.50",
    _disabled: {
      bg: "transparent",
      opacity: "0.4",
    },
  },
  _disabled: {
    opacity: "0.4",
  },
}));

const variantGhost = defineStyle(() => ({
  bg: "transparent",
  color: "action.primary",
  border: "none",
  fontWeight: "bold",
  _hover: {
    bg: "blue.50",
    _disabled: {
      bg: "transparent",
      opacity: "0.4",
    },
  },
  _disabled: {
    opacity: "0.4",
  },
}));

const variantLine = defineStyle(() => ({
  tablist: {
    border: "none",
  },
  tab: {
    color: "text",
    borderBottom: "1px",
    borderColor: "border",
    _active: {
      color: "text.primary",
      borderColor: "border.primary",
    },
    _selected: {
      color: "text.primary",
      borderColor: "border.primary",
    },
  },
}));

const skeletonBaseStyle = defineStyle(() => ({
  opacity: 1,
}));

export const theme = extendTheme({
  fonts,
  colors,
  config,
  semanticTokens,
  textStyles,
  components: {
    Button: {
      variants: {
        primary: primaryButton,
        critical: variantCritical,
        criticalOutline: variantCriticalOutline,
        dark: darkButton,
        actioned: actionedButton,
        line: lineButton,
        outline: variantOutline,
        ghost: variantGhost,
      },
    },
    Tabs: {
      variants: {
        line: variantLine,
      },
    },
    Skeleton: {
      baseStyle: skeletonBaseStyle,
    },
    Input: {
      variants: {
        outline: () => ({
          field: {
            _placeholder: {
              color: defaultChakraTheme.colors.gray[300],
            },
          },
        }),
      },
    },
    Textarea: {
      variants: {
        outline: () => ({
          _placeholder: {
            color: defaultChakraTheme.colors.gray[300],
          },
        }),
      },
    },
    Link: {
      variants: {
        primary: {
          color: "#3182CE",
          textDecoration: "underline",
        },
      },
    },
  },
});
